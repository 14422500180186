<template>
  <div class="lux-dashboard">
    <div class="lux-list-tree">
      <template v-for="(item, idx) in list">
        <a href="javascript:void(0);" :key="idx" :class="['module-nva-but', listIndex == idx ? 'active' : '']" @click="listBut(idx)">
          <img :src="isImg(item)" />
          [{{ item.isOuter ? '外部' : '内部' }}]{{item.name}}
          <span :key="idx+'s'" class="lux-list-num">{{item.luxValue || '-'}}</span>
        </a>
      </template>
    </div>
    <div class="lux-right-box">
      <!-- <div class="lux-right-top">
        <p>{{showLang('lux.device.operate')}}</p>
        <div class="lux-groupList">
          <template v-for="(item, idx) in showGroups">
            <div class="lux-groupList-box" :key="idx" @click="clickItem(item)">
              <img :src="img.lux.nav.treeIconLarge" />
              <div>{{item.name}}</div>
            </div>
          </template>
        </div>
      </div> -->
      <div class="lux-right-bottom">
        <div class="lux-bottom-lux">
          <div class="bottom-lux-top">
            <div class="bottom-lux-name"> <img :src="isImg(currentLux)" /> {{currentLux ? currentLux.name : '-'}}</div>
            <!-- <div class="bottom-lux-but">
              <a href="javascript:void(0);" @click="viewHistory(currentLux)">{{showLang('history.data')}}</a>
            </div> -->
          </div>
          <div class="bottom-lux-icon">
            <img :src="img.lux.nav.luxMix0" alt="">
            <div>
              <div class="bottom-icon-name">{{showLang('cmd.lux')}}：</div>
              <div class="bottom-icon-value">{{ currentLux ? currentLux.luxValue.toFixed(0) : '-'}} lux</div>
            </div>
          </div>
          <div class="bottom-icon-box">
            <div class="bottom-val-box1">
              <img :src="img.lux.nav.temperature" alt="">
              <div>
                <div class="bottom-val-name">{{showLang('com.lighting.T')}}</div>
                <div class="bottom-val-value">{{ currentLux ? currentLux.temperature.toFixed(0) : '-'}} ℃</div>
              </div>
            </div>
           
          </div>
          <div class="bottom-icon-box">
            <div class="bottom-val-box2">
              <img :src="img.lux.nav.humidity" alt="">
              <div>
                <div class="bottom-val-name">{{showLang('lux.humidity')}}</div>
                <div class="bottom-val-value">{{ currentLux ? currentLux.humidity.toFixed(0) : '-'}} %</div>
              </div>
            </div>
           
          </div>
          <div class="bottom-icon-box">
            <div class="bottom-val-box1">
              <img :src="img.lux.nav.time" alt="">
              <div>
                <div class="bottom-val-name">{{showLang('lux.Refresh')}}</div>
                <div class="bottom-val-value">{{currentLux && currentLux.fireTime ? new Date(currentLux.fireTime).format('yyyy-MM-dd HH:mm:ss') : '-'}} </div>
              </div>
            </div>
            <!-- <div class="bottom-val-box2">
              <img :src="img.lux.nav.monitor" alt="">
              <div>
                <div class="bottom-val-name">{{showLang('lux.Monitoring.area')}}</div>
                <div class="bottom-val-value" :title="curentGroupName">{{curentGroupName}}</div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="lux-bottom-chart">
          <p class="lux-chart-name">{{showLang('lux.trend')}}</p>
          <div class="lux-chart-box">
            <LineChart ref="lineChart" :option="lineChartData" id="chartsLineData" :key="timer" />
          </div>
        </div>
      </div>
    </div>
    <!-- <ModalControlGroup v-model="showControlModal" :item="selectedItem" /> -->
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import ModalControlGroup from './ModalControlGroup'
import LineChart from '@/components/charts/line'
export default {
  name: 'LuxDashborad12',
  components: {
    // ModalControlGroup,
    LineChart
  },
  data() {
    return {
      list: [],
      listIndex: 0,
      ticker: null,
      timeout: null,
      groups: [],
      showGroups: [],
      selectedItem: {},
      showControlModal: false,
      timer: 0,
      lineChartData: {
        id: "lineChartData",
        title: '',
        unit: '',
        x: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun5"],
        series: [
          {
            name: '光照度',
            data: [0],
          },
          {
            name: '温度',
            data: [0],
          },
          {
            name: '湿度',
            data: [0],
          }
        ],
        // cover: {
        //   legend: {
        //     data: ['光照度', '温度', '湿度'],
        //   },
        // },
        setSeries: {
          smooth: true, //平滑折线图
        }
      },
    }
  },
  computed: {
    ...mapState('auth', ['agentChangeValue']),
    ...mapState('group', ['selectedNode']),
    ...mapState('common', ['powerBoxTypes', 'productCodes']),
    ...mapState('cmd', ['luxDataChanged', 'luxContent']),
    ...mapGetters('auth', ['showLang', 'funCodes']),
    online: function () {
      return function (item) {
        if (item.ts <= 0) return '离线';
        if ((new Date().getTime() - item.ts) > 120 * 1000) return '离线';
        return '在线'
      }
    },
    currentLux: function () {
      if (this.list.length == 0) return null;
      if (this.listIndex >= this.list.length) return null;
      if (this.listIndex < 0) return null;
      return this.list[this.listIndex];
    },
    curentGroupName: function () {
      if (!this.currentLux) return '-';
      if (!this.currentLux.outputGroups) return '-';
      if (this.currentLux.outputGroups.length == 0) return '-';
      let names = [];
      this.currentLux.outputGroups.map(item => {
        names.push(item.name);
      })
      return names.join(',');
    },
  },
  watch: {
    // agentChangeValue() {
    //   this.debounceInitData();
    // },
    // luxDataChanged() {
    //   for(let item of this.list){
    //     let ctnt = this.luxContent[`s${item.id}`];
    //     if(!ctnt)continue;
    //     item.online = true;
    //     item.l = ctnt.l;
    //     item.t = ctnt.t;
    //     item.h = ctnt.h;
    //     this.$set(item, 'ls', ctnt.ls);
    //     item.ts = ctnt.time;
    //     if(this.currentLux && item.id == this.currentLux.id){
    //       this.lineChartData.x.push(new Date(ctnt.time).format('yyyy-MM-dd HH:mm:ss'));
    //       this.lineChartData.series[0].data.push(ctnt.l.toFixed(0));
    //       this.lineChartData.series[2].data.push(ctnt.t.toFixed(0));
    //       this.lineChartData.series[1].data.push(ctnt.h.toFixed(0));
    //       this.timer = Math.random();
    //       // console.log('refresh page data', item)
    //     }
    //   }
    // },
  },
  mounted: function () {
    this.getList();
    this.ticker = setInterval(this.checkOnline, 1000);
    this.lineChartData.series[0].name = this.showLang('cmd.lux')
    this.lineChartData.series[1].name = this.showLang('com.lighting.T')
    this.lineChartData.series[2].name = this.showLang('lux.humidity')
    window.eventBus.$on('luxRealDataComing', params => {
      this.setLuxRealData(params);
      this.setListData(params);
    })
  },
  destroyed: function () {
    window.eventBus.$off('luxRealDataComing');
    if (this.ticker) {
      clearInterval(this.ticker);
      this.ticker = null;
    }
  },
  methods: {
    setListData(params) {
      let arr = this.list.filter(el => el.id == params.id)
      if (arr.length > 0) {
        arr[0].humidity = params.hum;
        arr[0].luxValue = params.lux;
        arr[0].temperature = params.temp;
        arr[0].fireTime = params.time;
      }
      this.checkOnline()
    },
    setLuxRealData: function (params) {
      // console.log('lux data coming', params.time, params, this.listIndex, this.list[this.listIndex])
      let item = this.list[this.listIndex];
      if (item.id != params.id) return;
      this.$set(item, 'luxValue', params.lux);
      this.$set(item, 'temperature', params.temp);
      this.$set(item, 'humidity', params.hum);
      // this.$set(item, 'luxValues', params.luxs);
      // this.$set(item, 'humiditys', params.hums);
      // this.$set(item, 'temperatures', params.temps);
      this.$set(item, 'fireTime', params.time);
      if (this.lineChartData.x.length > 1000) {
        this.lineChartData.x.splice(0, (this.lineChartData.x.length - 1000));
        this.lineChartData.series[0].data.splice(0, (this.lineChartData.x.length - 1000));
        this.lineChartData.series[2].data.splice(0, (this.lineChartData.x.length - 1000));
        this.lineChartData.series[1].data.splice(0, (this.lineChartData.x.length - 1000));
      }
      this.lineChartData.x.push(params.time);
      this.lineChartData.series[0].data.push(params.lux.toFixed(0));
      this.lineChartData.series[2].data.push(params.temp.toFixed(0));
      this.lineChartData.series[1].data.push(params.hum.toFixed(0));
      this.timer = Math.random();
    },
    isImg(item) {
      if (!item) return false;
      if (!item.online) {
        return this.img.lux.nav.offline;
      } else {
        return this.img.lux.nav.online;
      }
    },
    listBut(index) {
      this.listIndex = index;
      this.history(this.list[index]);
      // this.showGroups1(this.list[index]);
    },
    showGroups1(item) {
      this.$set(this, 'showGroups', item.outputGroups)
    },
    clickItem: function (item) {
      this.selectedItem = item;
      this.showControlModal = true;
    },
    debounceInitData: function () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.getList, 500);
    },
    checkOnline: function () {

      let time = new Date().getTime();
      for (let item of this.list) {
        let diff = time - new Date(item.fireTime).getTime();
        let online = diff < 120 * 1000;
        this.$set(item, 'online', online)
        // console.log("check online", time, item.fireTime, diff, item.online)
      }
    },
    getImgType: function (item) {
      if (!item.online) {
        return this.img.tree.station.offliner;
      } else {
        return this.img.tree.station.onlineOn;
      }
    },
    viewHistory: function (item) {
      if (!item) return;
      this.$emit('doAction', { cmd: 'gotoSearch', stationId: item.id })
    },
    history(el) {
      let now = new Date();
      now.setHours(now.getHours() + 1);
      let end = now.format('yyyy-MM-dd HH:mm:ss');
      now.setHours(now.getHours() - 3);
      let start = now.format('yyyy-MM-dd HH:mm:ss');
      let ajaxData = {
        "stationId": el.id,
        "start": start,
        "end": end,
        "index": 1,
        "size": 20000
      }
      this.$axios.post(`device/lux/QueryLogs`, ajaxData).then(res => {
        if (res.code === 0) {
          this.lineChartData.x = [];
          this.lineChartData.series[0].data = [];
          this.lineChartData.series[2].data = [];
          this.lineChartData.series[1].data = [];
          res.data.list.sort((a, b) => {
            if (a.fireTime == b.fireTime) return 0;
            return a.fireTime > b.fireTime ? 1 : -1;
          })
          res.data.list.forEach(el => {
            // console.log('get lux log', el)
            this.lineChartData.x.push(el.fireTime);
            this.lineChartData.series[0].data.push(el.luxValue.toFixed(0));
            this.lineChartData.series[2].data.push(el.temperature.toFixed(0));
            this.lineChartData.series[1].data.push(el.humidity.toFixed(0));
          });
          this.timer = Math.random();
        } else {
          // this.$Message.error(res.data);
        }
      });
    },
    getList: function () {
      this.$axios.post(`device/lux/QueryState`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'list', res.data);
        // console.log('get data', res.data)
        if (res.data.length > 0) {
          this.listBut(0);
        }
        this.checkOnline();
      });
    },
  }
}
</script>
<style scoped>
.lux-dashboard {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.lux-list-tree {
  width: 300px;
  flex: none;
  /* height: 100%; */
  background: #ffffff;
  border: solid 1px rgba(197, 197, 197, 0.37);
  padding-top: 20px;
  margin-right: 10px;
}
.lux-list-num {
  float: right;
  margin-right: 20px;
}
.lux-right-box {
  border: solid 1px rgba(197, 197, 197, 0.37);
  width: 500px;
  flex: auto;
  /* height: 100%; */
}
.lux-right-top {
  background: #ffffff;
  padding: 20px;
  height: 228px;
}
.lux-right-top p {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.lux-groupList {
  display: flex;
  flex-direction: revert;
  margin-top: 30px;
}
.lux-groupList-box {
  text-align: center;
  margin: 0 80px 0 20px;
}
.lux-groupList .lux-groupList-box div {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  margin-top: 10px;
}
.module-nva-but {
  width: 300px;
  height: 48px;
  display: inline-block;
  line-height: 48px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.module-nva-but.active {
  background: #ddeffe;
}
.module-nva-but.active::after {
  content: "";
  width: calc(100%);
  height: 48px;
  display: block;
  margin: -48px auto 0;
  border-left: 4px solid #3880fc;
  border-radius: 0px 6px 6px 0px;
}
.module-nva-but img {
  vertical-align: middle;
  margin-left: 10px;
  margin-right: 10px;
}
.lux-right-bottom {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.lux-bottom-lux {
  width: 450px;
  background: #ffffff;
  padding: 30px;
}
.lux-bottom-chart {
  width: calc(100% - 450px);
  background: #ffffff;
  padding: 20px;
}
.bottom-lux-top {
  display: flex;
  justify-content: space-between;
}
.bottom-lux-name {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.bottom-lux-name img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.bottom-lux-but a {
  width: 79px;
  height: 32px;
  background: #3880fc;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  text-align: center;
  color: #ffffff;
  line-height: 32px;
}
.bottom-lux-but a img {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
}
.bottom-lux-icon {
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
}
.bottom-lux-icon img {
  margin-right: 39px;
}
.bottom-icon-name {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  line-height: 26px;
  margin-top: 20px;
}
.bottom-icon-value {
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  line-height: 26px;
  margin-top: 20px;
}
.bottom-icon-box {
  display: flex;
  justify-content: flex-start;
  margin-top: 35px;
}

.bottom-val-box1 {
  display: flex;
  justify-content: flex-start;
  width: 330px;
}
.bottom-val-box2 {
  display: flex;
  justify-content: flex-start;
}
.bottom-val-name {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  margin-left: 35px;
}
.bottom-val-value {
  width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #24334a;
  line-height: 26px;
  margin-left: 35px;
}
.lux-chart-name {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.lux-chart-box {
  width: 100%;
  height: 460px;
  margin-bottom: 20px;
}
</style>